/*global _, $, jQuery, MatchHeightHelper*/
import Helper from './services/Helper/Helper.js';
import Select from './widgets/Select/Select.js';

(function($) {
  $(function() {
    var
      $body                  = $('body'),
      country                = $body.data('country') ? $body.data('country') : 'fr'
    ;

    // Init linkify
    require('linkifyjs/jquery')($, document);
    $('.linkify').linkify();

    // Init Fancybox
    require('fancybox/dist/js/jquery.fancybox.cjs.js')($);
    $('.fancybox').fancybox();

    /*
     * JQUERY VALIDATE.
     * - Javascript form validation before submit.
     */
    if ('undefined' !== typeof $.fn.validate && $.isFunction($.fn.validate)) {
      /*
       * Configure jquery validate for Bootstrap 3.
       */
      $.validator.setDefaults({
        'highlight': function(element) {
          // Add the .has-error class to the form-group.
          $(element).closest('.form-group').addClass('has-error');
        },
        'unhighlight': function(element) {
          // Remove the .has-error class from the form-group.
          $(element).closest('.form-group').removeClass('has-error');
        },
        'errorElement': 'span',
        'errorClass': 'help-block',
        'errorPlacement': function(error, element) {
          // Handle the error placement for checkbox & radio.
          if (element.parent('.input-group').length || 'checkbox' === element.prop('type')  || 'radio' === element.prop('type')) {
            error.appendTo(element.parent());
          } else {
            error.insertAfter(element);
          }
        }
      });

      // Add a polyfill method to test custom regexp against user input.
      $.validator.addMethod('cemail', function(value, element, regexp)  {
        regexp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);

        return this.optional(element) || regexp.test(value);
      }, 'Please enter a valid email address');

      $.validator.addMethod('pattern', function(value, element, regexp)  {
        var pattern = new RegExp(regexp);

        return this.optional(element) || pattern.test(value);
      });

      // $.validator.addMethod('alphanumeric', function(value, element) {
      //   var regexp = new RegExp(/^[\u4e00-\u9fff_A-Za-zа-яёЁÀ-Ÿ][\u4e00-\u9fff_a-zа-яёЁÀ-Ÿ0-9\-\s]+$/i);

      //   return regexp.test(value);
      // }, 'Letters, numbers, spaces or dashes only');

      /*
       * Enable jquery-validate for all matching (not just the first) using a each() closure in case there's two form on
       * the same page, eg. sidebar and footer.
       */
      $('.js-contact-form, .js-newsletter-form').each(function(i, e) {
        $(e).validate({
          'ignore': [],
          'rules': {
            'front_message_type[email]': {
              'email': false,
              'cemail': true
            },
            'front_newsletter_type[email]': {
              'email': false,
              'cemail': true
            },
            'hiddenRecaptcha': {
              required: true
            }
          }
        });
      });

      $('.js-press-review-form').validate({
        'rules': {
          'front_press_review_type[pressReviewFields][costCenter]': {
            pattern: "^[0-9]{4}$",
          },
          'front_press_review_type[pressReviewFields][entityCode]' : {
            pattern: "^[a-zA-Z]{3}[0-9]{3}$",
          },
        },
        'messages': {
          'front_press_review_type[pressReviewFields][costCenter]': {
            pattern: $('[name="front_press_review_type[pressReviewFields][costCenter]"]').data('msg-error')
          },
          'front_press_review_type[pressReviewFields][entityCode]' : {
            pattern: $('[name="front_press_review_type[pressReviewFields][entityCode]"]').data('msg-error')
          },
        }
      })

      $('.max_length_rule').each(function() {
        $(this).rules('add', {
          maxlength: 100,
          alphanumeric: true,
          messages: {
            maxlength: $(this).data('msg-error')
          }
        })
      })

      $('.email_rule').each(function() {
        $(this).rules('add', {
          email: false,
          cemail: true,
          messages: {
            maxlength: $(this).data('msg-error')
          }
        })
      })

      $('.js-press-review-form input').blur(function() {
        var isValid = $(this).attr('aria-invalid');
        var isValid = isValid === 'true';

        $('#front_press_review_type_submit').attr('disabled', isValid);
      })
    }

    /**
     * MATCH HEIGHT.
     * - Make sure Bootstrap 3 grid does not explode because of various thumbnail/col-* heights.
     */
    $('.match-height .thumbnail.grid').not('.file-icon').matchHeight({
      byRow: false
    });
    $('.js-match-height .card').matchHeight();

    $('.js-match-height .js-match-height-item').matchHeight();

    $('.js-buckle--spokesperson').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;
      MatchHeightHelper.update($container[0], true, '.js-match-height .card');
    });

    $('.js-buckle--assets').on('showing.jq.offie-dropdown', '[data-toggle="offie-dropdown"]', function (event) {
      var buckle = $(event.target).closest('.js-buckle--folder').data('buckle');

      // Fetch content of the folder.
      buckle.fetch({});
    });

    $('.js-buckle--assets').on('ajaxComplete.jq.buckle', function (event) {
      var
        $element = event.buckle.getElement(),
        $container = event.buckle.getContainer()
      ;

      // Update the grid.
      MatchHeightHelper.update($container[0], true, '.js-match-height .card');

      // Enable any child buckle component (folders).
      $element.find('[data-toggle="buckle"]').buckle();

      // Enable any offie dropdown component (folders).
      $element.find('[data-toggle="offie-dropdown"]').offieDropdown();
    });

    // Datetimepicker
    var dateTimePickerOptions = {
      'format': 'yyyy-mm-dd',
      'startView': 'year',
      'minView': 'month',
      'fontAwesome': true,
      'autoclose': true
    };

    $('#front_search_type_start').datetimepicker(dateTimePickerOptions);
    $('#front_search_type_end').datetimepicker(dateTimePickerOptions);

    require('./modules/share.js');

    Helper
      .convertToArray(document.querySelectorAll('select'))
      .map((select) => new Select(select))
    ;

    // Press Review
    var pressReviewLink = $('.section--press_review-jumbotron .section-btn').attr('href');

    $('.section--press-review-index .section-btn').attr('href', pressReviewLink);

    function toggleAriaExpanded(element) {
      let ariaExpandedAttr = element.attr('aria-expanded') ?? 'false';

      element.attr('aria-expanded', ariaExpandedAttr === 'false' ? 'true' : 'false');
    }

    let countDisplay = 1;
    const navAccessEl = $(".med-nav-access");

    const scrollToTop = () => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    };

    const toggleNavAccess = () => {
      navAccessEl.toggle();
    };

    document.addEventListener('keyup', (e) => {
      if (e.key === 'Tab') {
        if (countDisplay === 1) {
          scrollToTop();
          toggleNavAccess();
          navAccessEl.focus();
        } else if (countDisplay > 1 && navAccessEl.is(":visible")) {
          toggleNavAccess();
        }
        countDisplay++;
      }
    });

    document.addEventListener('click', () => {
      if (navAccessEl.is(":visible")) {
        toggleNavAccess();
      }
      countDisplay = 1;
    });

    // Homepage Carousel
    $('.section--web_publications_highlight .gallery-list').find('.gallery-item').first().addClass('active');

    $('.section--web_publications_highlight .section-block').attr({ 'data-ride': 'carousel', 'id': 'carousel-section--web_publications_highlight', 'class': 'carousel slide' }).carousel();

    // Header Nav Menu
    function changeColorHover(currentNavlink) {
      let color = currentNavlink.data('color');

      if (typeof color !== 'undefined' && color !== false ) {
        currentNavlink.find('.nav-link-text').css('color', '#fff');
        currentNavlink.parent().css('background-color', color);
      }
    }

    function changeColorLeave(currentNavlink) {
      currentNavlink.find('.nav-link-text').css('color', '#000');
      currentNavlink.parent().css('background-color', 'inherit');
    }

    function activeMenuItem() {
      $('.navbar-element--main').not('.navbar-element-mobile').find('.nav-item').each(function (e) {
        let dataColor = $(this).find('a.nav-link').data('color');

        if ($(this).hasClass('active')) {
          $(this).css('background-color', dataColor);
          $(this).find('.nav-link-text').css('color', '#fff');
        }
      });
    }

    $(document).on('click', function(e) {
      let target = e.target;

      if (!$(target).is('.nav-item')) {
        $('.nav-item.js-offie').removeClass('active');
        $('.navbar-element--main').not('.navbar-element-mobile').find('.nav-item').css('background-color', 'transparent');
        $('.navbar-element--main').not('.navbar-element-mobile').find('.nav-link-text').css('color', '#000');
        activeMenuItem();
      }
    });

    $('.navbar-element--main').not('.navbar-element-mobile').find('.nav--channel-1 > .nav-item > .nav-link')
      .on('mouseover', function () {
        changeColorHover($(this));
      })
      .on('mouseleave', function () {
        if (!$(this).parent().hasClass('active')) {
          changeColorLeave($(this));
        }
      })
    ;

    $('.navbar-element.navbar-element--main .navbar-nav.nav--channel-1 > .nav-item > .nav-link').on('click', function (e) {
      $('.navbar-nav > .nav-item').removeClass('active');
      $(this).parent().addClass('active');
      $('.navbar-nav > .nav-item').not('.active').find('a.nav-link > .nav-link-text').css('color', '#000');
      $('.navbar-nav > .nav-item').not('.active').css('background-color', 'inherit');

      let color = $(this).data('color');

      if (typeof color !== 'undefined' && color !== false ) {

        let subMenu = $(this).parent().find('.offie-block');

        if (typeof subMenu !== 'undefined') {
          subMenu.find('a.nav-link > .nav-link-text').css('color', '#fff');
          subMenu.css('background-color', color);
        }
      }
    });

    activeMenuItem();

    // Newsletter Footer Block
    var newsletterForms  = document.querySelectorAll('.form--newsletter');
    var formElements     = newsletterForms.elements;
    var formFlashMessage = document.getElementById('success-flash-message');
    var grecaptchaResponse = document.getElementById('hiddenRecaptcha');

    newsletterForms.forEach( (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() && grecaptchaResponse.value !== '') {
          setTimeout(
            function () {
              var divs = form.querySelectorAll('div');
              for (let i = 0; i < divs.length; i++) {
                // Make unclickable each form divs element
                divs[i].style.pointerEvents = 'none';
              }
              // Display success flash message
              formFlashMessage.classList.remove('hidden');
              formFlashMessage.classList.add('show');
            },
            500
          );
        }
      });
    });

    $('.header-buger').on('click', function() {
      $('body').css('overflow', 'hidden');
      $('.block-menu-detail').addClass('active');
    });

    $('.close-menu-btn').on('click', function() {
      $('body').css('overflow', 'inherit');
      $('.block-menu-detail').removeClass('active');
    });

    $('.card--spokesperson.card--alt.js-offie').on('showing.jq.offie', function (event) {
      const cta = $(this).find('button.card-img');
      toggleAriaExpanded(cta);
    });

    $('.card--spokesperson.card--alt.js-offie').on('hiding.jq.offie', function (event) {
      const cta = $(this).find('button.card-img');
      toggleAriaExpanded(cta);
    });

    // Didomi - Display Banner
    let didomiBtn = document.getElementById('didomi-banner-btn') ?? null;
    if (didomiBtn != null) {
      didomiBtn.addEventListener('click', function() {
        window.Didomi?.preferences.show();
      });
    }
  });
}(jQuery));
